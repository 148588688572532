import { render, staticRenderFns } from "./KiosksItem.vue?vue&type=template&id=78dcd513&scoped=true"
import script from "./KiosksItem.vue?vue&type=script&lang=ts"
export * from "./KiosksItem.vue?vue&type=script&lang=ts"
import style0 from "./KiosksItem.vue?vue&type=style&index=0&id=78dcd513&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78dcd513",
  null
  
)

export default component.exports